import Vuex from 'vuex';
import IdentityTemplate from '@/modules/questionnaire/components/steps/common/identity/identity/IdentityTemplate';

import { types } from '@/store/types';

export default {
  title: 'Steps/Identity/Identity/IdentityTemplate',
  component: IdentityTemplate
};

const createStory = componentProps => () => ({
  componentProps,
  components: { IdentityTemplate },
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  store: new Vuex.Store({
    getters: {
      [types.getters.GET_LOCALE]: () => 'en',
      [types.getters.GET_USER_COUNTRY]: () => 'fr'
    }
  }),
  template: `<div :style="$options.wrapStyles">
    <identity-template v-bind="$options.componentProps" />
  </div>`
});

export const Base = createStory({
  lastName: 'Petrov',
  phone: '9215555555',
  phoneIsoCode: 'ru',
  smsAccepted: true,
  marketingSmsOffersAccepted: false,
  phoneCountries: ['GB', 'FR', 'ES', 'RU', 'BS']
});

export const WithoutPhone = createStory({
  lastName: 'Petrov',
  phone: '',
  phoneIsoCode: 'ru',
  smsAccepted: false,
  marketingSmsOffersAccepted: false,
  phoneCountries: ['GB', 'FR', 'ES', 'RU', 'BS']
});
